import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';

import { routes } from '@/app/app.routes';
import { MSALGuardConfigFactory, MSALInstanceFactory } from '@/app/core/utils/auth.util';
import { environment } from '@/environments/environment';
import { authInterceptor, errorInterceptor } from '@/shared/core';
import { ENVIRONMENT } from '@/shared/environment.token';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    provideExperimentalZonelessChangeDetection(),
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: ENVIRONMENT, useValue: environment },
  ],
};
