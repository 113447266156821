import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { DisplayMode, ValdemortConfig, ValdemortModule } from 'ngx-valdemort';
import { filter, Subject, takeUntil } from 'rxjs';

import { SideBarComponent } from '@/app/components/layout/sidebar/sidebar.component';
import { SpinnerComponent } from '@/shared/components';
import { LoggerService, SignalRService, UserService } from '@/shared/services';
import { TenantStore, UserStore } from '@/shared/stores';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, SideBarComponent, SpinnerComponent, ValdemortModule],
  selector: 'nvx-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  authService = inject(MsalService);
  msalBroadcastService = inject(MsalBroadcastService);
  msalGuardConfig = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG);
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  signalRService = inject(SignalRService);
  logger = inject(LoggerService);
  userService = inject(UserService);
  valdemortConfig = inject(ValdemortConfig);

  isAuthenticated = signal(false);
  isAppLoading = computed(() => this.userStore.isLoading());
  private readonly _destroying$ = new Subject<void>();

  ngOnInit(): void {
    this.valdemortConfig.errorsClasses = 'text-xs text-red-500';
    this.valdemortConfig.displayMode = DisplayMode.ONE;

    this.authService.handleRedirectObservable().subscribe();
    this.setIsAuthenticated();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.setIsAuthenticated();
        await this.loadUserDetails();
        await this.tenantStore.loadBrandTenants();
      });

    /* The following inProgress observable subscription allows the App to fetch data
    on every page refresh too, instead of just after login as we did in msalSubject observable subscription above. */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        if (this.isAuthenticated()) {
          await this.startSignalRConnection();
          await this.tenantStore.loadBrandTenants();
        }
      });
  }

  setIsAuthenticated() {
    this.isAuthenticated.set(this.authService.instance.getAllAccounts().length > 0);
  }

  logout() {
    this.tenantStore.resetState();
    this.userStore.resetState();
    this.authService.logoutRedirect();
  }

  async setActiveTenant() {
    const defaultCustomerTenantId = this.userStore.userProfile()?.defaultCustomerTenantId;
    const tenantId = defaultCustomerTenantId ?? this.userStore.currentUser()!.tenants[0].tenantId;
    await this.tenantStore.loadBrandTenants();
    const tenantIdList = this.userStore.tenants()!.map((tenant) => tenant.tenantId);
    this.userService.getActiveTenants(tenantIdList).then((response) => {
      this.tenantStore.setCustomerTenants(response.items);
      this.tenantStore.setActiveTenant(tenantId);
      this.tenantStore.setUserPermission(this.userStore.currentUser()?.tenantPermissions);
    });
  }

  async loadUserDetails() {
    this.userStore.setLoadingState(true);
    await this.userStore.loadCurrentUser();
    await this.userStore.loadMyUserProfile();
    await this.setActiveTenant();
    this.tenantStore.setUserPermission(this.userStore.currentUser()?.tenantPermissions);
    this.userStore.setLoadingState(false);
  }

  async startSignalRConnection() {
    await this.signalRService.startConnection();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
