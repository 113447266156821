import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { PrimeNGConfigType } from 'primeng/config';

const nvxPreset = definePreset(Aura, {
  semantic: {
    disabledOpacity: '1',
    formField: {
      paddingX: '0.625rem',
      paddingY: '0.375rem',
    },
    primary: {
      50: '#f3f8fc',
      100: '#e6f0f8',
      200: '#c8e0ef',
      300: '#97c6e2',
      400: '#5fa8d1',
      500: '#3a8dbd',
      600: '#266791',
      700: '#235b81',
      800: '#204d6c',
      900: '#20425a',
      950: '#152a3c',
    },
    colorScheme: {
      light: {
        primary: {
          color: '{primary.600}',
          hoverColor: '{primary.700}',
          activeColor: '{primary.700}',
        },
        highlight: {
          background: '#e7f5ff',
          focusBackground: '#e7f5ff',
          color: '{primary.color}',
          focusColor: '{primary.color}',
        },
      },
    },
  },
  components: {
    autocomplete: {
      colorScheme: {
        light: {
          dropdown: {
            color: '{surface.400}',
          },
        },
      },
    },
    button: {
      css: () => `
        .p-button:disabled {
          opacity: 0.6 !important;
        }
      `,
    },
    chip: {
      css: () => `
        .p-chip-label {
          overflow: auto;
        }
      `,
    },
    datatable: {
      headerCell: {
        background: '{gray.100}',
        color: '{primary.color}',
        hoverBackground: '{gray.100}',
        hoverColor: '{primary.color}',
        selectedBackground: '{gray.100}',
      },
      footerCell: {
        background: '{gray.100}',
      },
      css: () => `
        .p-datatable:not(.p-datatable-gridlines) > .p-datatable-table-container {
          border-radius: 0.375rem;
          border-width: 1px;
        }
        .p-datatable:not(.p-datatable-gridlines) .p-datatable-tbody > tr:last-child > td {
          border-width: 0;
        }
        .p-datatable:not(.p-datatable-gridlines) > .p-datatable-mask {
          border-radius: 0.375rem;
        }
        .p-datatable-thead > tr:nth-child(2) > th {
          background: #fff;
        }
        .p-sortable-column-icon {
          width: 0.875rem;
          height: 0.875rem;
          margin-top: 2.5px;
        }
      `,
    },
    dialog: {
      header: {
        padding: '0.5rem 1rem',
      },
      content: {
        padding: '1rem',
      },
      footer: {
        padding: '1rem',
      },
      css: () => `
        .p-dialog-header {
          border-bottom-width: 1px;
        }
        .p-dialog-footer {
          border-top-width: 1px;
        }
      `,
    },
    inputtext: {
      css: () => `
        .p-inputtext {
          font-size: 0.875rem;
        }
      `,
    },
    select: {
      css: () => `
        .p-select {
          font-size: 0.875rem;
        }
      `,
    },
    selectbutton: {
      borderRadius: '0.5rem',
    },
    stepper: {
      stepNumber: {
        activeBackground: '{primary.50}',
        activeBorderColor: '{primary.color}',
        activeColor: '{primary.color}',
      },
      css: () => `
        .p-step-number {
          border-width: 1px;        
        }
      `,
    },
    tag: {
      fontSize: '0.75rem',
    },
    textarea: {
      css: () => `
        .p-textarea {
          font-size: 0.875rem;
        }
      `,
    },
    togglebutton: {
      borderRadius: '0.25rem',
      disabledBorderColor: '{surface.300}',
      content: {
        checkedShadow: 'none',
      },
      colorScheme: {
        light: {
          background: '#fff',
          borderColor: '{surface.200}',
          checkedBackground: '{primary.color}',
          checkedBorderColor: '{primary.color}',
          checkedColor: '#fff',
          content: {
            checkedBackground: '{primary.color}',
          },
          hoverColor: '{primary.color}',
        },
      },
      css: () => `
        .p-togglebutton:disabled {
          opacity: 0.6;
        }
      `,
    },
  },
});

export const appThemeConfig: PrimeNGConfigType = {
  ripple: true,
  theme: {
    preset: nvxPreset,
    options: {
      darkModeSelector: false,
      cssLayer: {
        name: 'primeng',
        order: 'tailwind-base, primeng, tailwind-components, tailwind-utilities',
      },
    },
  },
};
