import { MsalGuardConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { environment } from '@/environments/environment';

function getAuthority(policyName: string) {
  return `https://${environment.msalConfig.domain}/${environment.msalConfig.tenantId}/${policyName}`;
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: getAuthority(environment.msalConfig.policies.signUpSignIn),
      knownAuthorities: [environment.msalConfig.domain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.msalConfig.apiScopes,
    },
  };
}
