import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, output, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TenantDetails } from 'consistent-api-nvx-internal-sdk-dev';
import { TenantOrganizationReadModel } from 'consistent-api-nvx-internal-sdk-dev/dist/models/TenantOrganizationReadModel';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { UserPreferenceStore } from '@/app/stores/user-preference.store';
import { NVXRole } from '@/app/types/nvx-role.enum';
import { AvatarComponent, IconComponent } from '@/shared/components';
import { isNotNullOrEmpty, isNullOrEmpty } from '@/shared/lib/core/utils/data-validation.util';
import { TenantStore, UserStore } from '@/shared/stores';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AvatarComponent,
    ButtonModule,
    CommonModule,
    IconComponent,
    ImageModule,
    MenuModule,
    RouterModule,
    TooltipModule,
  ],
  selector: 'nvx-layout-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent {
  unbrandedIcon = '';
  userStore = inject(UserStore);
  tenantStore = inject(TenantStore);
  userPreferenceStore = inject(UserPreferenceStore);
  router = inject(Router);
  onLogout = output();

  unreadNotifications = signal(false);
  isSideNavCollapsed = computed(() => this.userPreferenceStore.isSideNavCollpased());

  navLinks = [
    {
      label: 'Home',
      icon: 'mdi:home-outline',
      to: '/home',
      role: NVXRole.FE_DEFAULT,
    },
    {
      label: 'Notifications',
      icon: 'mdi:bell-outline',
      to: '/notifications',
      role: NVXRole.FE_NOTIFICATIONREAD,
    },
    {
      label: 'Smart Tasks',
      icon: 'material-symbols:widgets-outline',
      to: '/smart-tasks',
      role: NVXRole.FE_SMARTTASKREAD,
    },
    {
      label: 'Shipments',
      icon: 'fluent:vehicle-ship-24-regular',
      to: '/shipments',
      role: NVXRole.FE_SHIPMENTREAD,
    },
    {
      label: 'New Shipment',
      icon: 'ic:outline-add-location-alt',
      to: '/shipment/new',
      role: NVXRole.FE_SHIPMENTCREATE,
    },
    {
      label: 'Address Book',
      icon: 'ic:outline-menu-book',
      to: '/address-book',
      role: NVXRole.FE_ADDRESSBOOKREAD,
    },
    {
      label: 'Stock',
      icon: 'clarity:container-line',
      to: '/stock',
      role: NVXRole.FE_STOCKOVERVIEWREAD,
    },
    {
      label: 'Billing',
      icon: 'ic:outline-money',
      to: '/billing/invoices',
      role: NVXRole.FE_BILLINGREAD,
    },
  ];

  brandIcons = [
    {
      id: 'neele-vat',
      tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
      name: 'Neele-vat',
      src: 'assets/icons/brand/neelevat_icon.svg',
    },
    {
      id: 'oostvogels',
      tenantId: '657a9288-c78c-4cb5-af05-a69b77423697',
      name: 'Oostvogels',
      src: 'assets/icons/brand/oostvogels_icon.svg',
    },
  ];

  customerIcons = [
    {
      id: 'prinova',
      tenantId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'Prinova',
      src: 'assets/icons/customer/prinova_icon.svg',
    },
  ];

  tenantOptions = computed<MenuItem[] | undefined>(() => {
    return this.userStore.tenants()?.map((tenant) => {
      return {
        id: tenant.tenantId,
        label: tenant.tenantName,
        command: () => {
          this.onTenantChange({
            tenantId: tenant.tenantId,
            tenantName: tenant.tenantName,
          });
        },
      };
    });
  });

  onTenantChange(tenant: TenantDetails) {
    this.tenantStore.setActiveTenant(tenant.tenantId);
    this.tenantStore.setUserPermission(this.userStore.currentUser()?.tenantPermissions);
    const newLocation = isNotNullOrEmpty(this.tenantStore.userRoles()) ? '/home' : '/unauthorized';
    this.router.navigateByUrl(newLocation);
  }

  brandTenantOptions = computed<MenuItem[] | undefined>(() => {
    return this.tenantStore.brandTenants()?.map((tenant) => {
      return {
        id: tenant.tenantId,
        label: tenant.name,
        command: () => {
          this.onBrandTenantChange({
            id: tenant.tenantId,
            tenantId: tenant.tenantId,
            name: tenant.name,
            type: tenant.type,
            isEnabled: tenant.isEnabled,
            organizationName: tenant.organizationName,
            organizationId: tenant.organizationId,
          });
        },
      };
    });
  });

  onBrandTenantChange(tenant: TenantOrganizationReadModel) {
    this.tenantStore.setActiveBrandTenant(tenant);
    window.location.reload();
  }

  roleBasedNavLinks = computed(() => {
    const activeRoles = this.tenantStore.userRoles();
    return this.navLinks.filter((navLink) => activeRoles.includes(navLink.role));
  });

  toggleSideNav = () => {
    this.userPreferenceStore.toggleSideNav();
  };

  isLinkValid(link: string): boolean {
    const currentRoute = this.router.url;
    if (currentRoute.startsWith('/shipment/') && link.startsWith('/shipment/')) return true;
    if (currentRoute.startsWith(link)) return true;
    return false;
  }

  getCurrentBrandTenant() {
    const brandIcon = this.brandIcons.find((icon) => icon.tenantId === this.tenantStore.activeBrandTenant()?.tenantId);
    return brandIcon?.src || 'assets/icons/unbranded_icon.svg';
  }

  getCurrentCustomerTenant() {
    if (isNullOrEmpty(this.tenantStore.activeCustomerTenant()?.tenantId)) {
      return 'assets/icons/unbranded_icon.svg';
    }

    const brandIcon = this.customerIcons.find(
      (icon) => icon.tenantId === this.tenantStore.activeCustomerTenant()!.tenantId
    );
    return brandIcon?.src || 'assets/icons/unbranded_icon.svg';
  }

  logout = () => {
    this.onLogout.emit();
  };
}
